import "../styles/PalletDelivery.scss";
import { ScrollAnimation } from "../components/ScrollAnimation/ScrollAnimation";

export function PalletDelivery() {
  return (
    <div className="palletdelivery-container">
      <ScrollAnimation>
        <div className="palletdelivery-content">
          <div className="palletdelivery-text">
            <p className="above-title">DIMENZIJE 120x80, 1500kg</p>
            <h2 className="service-title">PALETNA DOSTAVA</h2>
            <p className="description">
              Osiguravamo siguran prijevoz različitih vrsta robe, bilo da je
              riječ o industrijskom materijalu, gotovim proizvodima, pakiranim
              artiklima ili bilo kojoj vrsti tereta koja se transportira na
              paletama. Naša vozila su opremljena za siguran prijenos i
              manipulaciju s paletama svih veličina.
            </p>
          </div>
          <div className="palletdelivery-image">
            <img
              src="/assets/services/paletna_dostava.jpg"
              alt="paleta"
              className="img1"
            />
            <img
              src="/assets/services/paleta.jpg"
              alt="paleta"
              className="img2"
            />
            <img
              src="/assets/services/paketi.jpg"
              alt="paket"
              className="img3"
            />
          </div>
        </div>
      </ScrollAnimation>
    </div>
  );
}
